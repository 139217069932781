import * as styles from './PodcastTagTemplate.module.scss'

import React from 'react'
import { graphql, Link, PageProps } from 'gatsby'

import Layout from 'components/layout/Layout'
import PageMetaData from 'src/components/layout/PageMetaData'

import { EpisodeList } from 'src/components/podcast/EpisodeList'

interface Props extends PageProps<Queries.PodcastTagTemplateQuery> {
  pageContext: {
    title: string
    url: string
    tag: {
      slug: string
      name: string
      episodeCount: number
    }
  }
}

const PodcastTagTemplate: React.FC<Props> = (props) => {
  const { tag } = props.pageContext

  const { podcasts } = props.data.allMdx

  return (
    <Layout {...props.pageContext} includeNewsletterSignup>
      <div className={styles.header}>
        <div className={styles.pageTitle}>
          {podcasts.length} Episodes Tagged &ldquo;{tag.name}&rdquo;
        </div>

        <div>
          <Link to="/podcast/">&larr; All Episodes</Link>
        </div>
      </div>

      <EpisodeList podcasts={podcasts} activeTag={tag.slug} />
    </Layout>
  )
}

export const query = graphql`
  query PodcastTagTemplate($tagName: String!) {
    allMdx(
      filter: {
        fields: { type: { eq: "podcast-notes" } }
        frontmatter: { tags: { in: [$tagName] } }
      }
      sort: { frontmatter: { date: DESC } }
    ) {
      podcasts: nodes {
        id
        frontmatter {
          title
          date(formatString: "MMMM Do, YYYY")
          excerpt
          episodeSlug
          episodeNumber
          episodeOrder
          tags
        }
      }
    }
  }
`

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: props.pageContext.title,
        description: `Episodes tagged ${props.pageContext.tag.name} on Lucky Paper Radio A podcast about Magic: the Gathering`,
        ogImageURL: 'https://luckypaper.co/og-images/lucky-paper-radio.jpg',
        rssFeed: {
          title: 'Lucky Paper Radio',
          url: 'https://anchor.fm/s/29f49e0c/podcast/rss',
        },
      }}
    />
  )
}

export default PodcastTagTemplate
